var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showImageViewerDialog, fullscreen: "" } },
    [
      _c(
        "v-card",
        { staticClass: "d-flex flex-column", attrs: { height: "100vh" } },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0 flex-grow-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("div", [_vm._v("View Image")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("view-image-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass:
                "\n        overflow-auto\n        d-flex\n        justify-center\n        align-center\n        flex-column flex-grow-1\n      "
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-center align-center flex-grow-1"
                },
                [_c("img", { attrs: { src: _vm.selectedFile.s3_file_path } })]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
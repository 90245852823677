<template>
  <v-dialog :value="showImageViewerDialog" fullscreen>
    <v-card class="d-flex flex-column" height="100vh">
      <v-toolbar
        dark
        class="elevation-0 flex-grow-0"
        width="100%"
        color="#3F51B5"
      >
        <div>View Image</div>
        <v-spacer />
        <v-btn icon @click="$emit('view-image-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="
          overflow-auto
          d-flex
          justify-center
          align-center
          flex-column flex-grow-1
        "
      >
        <div class="d-flex justify-center align-center flex-grow-1">
          <img :src="selectedFile.s3_file_path" />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";

export default {
  name: "ImageViewerDialog",
  props: {
    showImageViewerDialog: Boolean,
    selectedFile: Object,
  },
  data() {
    return {
      mdiClose,
    };
  },
};
</script>

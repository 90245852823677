var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showFilesFilterDialog,
        "max-width": "550px",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", color: "#3F51B5" }
            },
            [
              _c("v-toolbar-title", [_vm._v("Filter")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("files-filter-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-3" },
            [
              _c("div", [_vm._v("Filter")]),
              _c(
                "v-card",
                { staticClass: "mb-5 px-0", attrs: { elevation: "0" } },
                [
                  _c("v-card-text", { staticClass: "px-0 mx-0" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-wrap gap" },
                      _vm._l(_vm.filterChoices, function(f) {
                        return _c(
                          "v-chip",
                          {
                            key: f.selectedField,
                            attrs: { close: "" },
                            on: {
                              "click:close": function($event) {
                                return _vm.removeFilter(f.selectedField)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("filesFieldMap")(f.selectedField)
                                ) +
                                " "
                            ),
                            _c("MatchIcon", {
                              staticClass: "mx-2",
                              attrs: { value: f.selectedMatchChoice }
                            }),
                            _vm._v(" " + _vm._s(f.selectedMatchValue) + " ")
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ])
                ],
                1
              ),
              _c("div", [_vm._v("Add to Filter")]),
              _c("validation-observer", {
                ref: "filterForm",
                attrs: { slim: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var isFormValid = ref.valid
                      return [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "align-center justify-space-between gap",
                                class: {
                                  "d-flex": _vm.$vuetify.breakpoint.smAndUp
                                }
                              },
                              [
                                _c("v-select", {
                                  style: {
                                    width: _vm.$vuetify.breakpoint.smAndUp
                                      ? "100%"
                                      : undefined
                                  },
                                  attrs: {
                                    items: _vm.fieldChoices,
                                    "item-text": "label",
                                    "item-value": "value",
                                    label: "Field",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: _vm.selectedField,
                                    callback: function($$v) {
                                      _vm.selectedField = $$v
                                    },
                                    expression: "selectedField"
                                  }
                                }),
                                _c("v-select", {
                                  class: {
                                    "field-select":
                                      _vm.$vuetify.breakpoint.smAndUp
                                  },
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    items: _vm.filteredMatchChoices,
                                    "item-text": "label",
                                    "item-value": "value",
                                    flat: "",
                                    "hide-details": ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item",
                                        fn: function(ref) {
                                          var ref_item = ref.item
                                          var label = ref_item.label
                                          var value = ref_item.value
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _c("MatchIcon", {
                                                  attrs: { value: value }
                                                }),
                                                _vm._v(
                                                  " " + _vm._s(label) + " "
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "selection",
                                        fn: function(ref) {
                                          var ref_item = ref.item
                                          var label = ref_item.label
                                          var value = ref_item.value
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _c("MatchIcon", {
                                                  attrs: { value: value }
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$vuetify.breakpoint
                                                        .smAndUp
                                                        ? undefined
                                                        : label
                                                    ) +
                                                    " "
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.selectedMatchChoice,
                                    callback: function($$v) {
                                      _vm.selectedMatchChoice = $$v
                                    },
                                    expression: "selectedMatchChoice"
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    style: {
                                      width: _vm.$vuetify.breakpoint.smAndUp
                                        ? "100%"
                                        : undefined
                                    }
                                  },
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          "close-on-click": false,
                                          "close-on-content-click": false
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Value",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            var valid =
                                                              ref.valid
                                                            return [
                                                              _c(
                                                                "v-text-field",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      "hide-details":
                                                                        "",
                                                                      "error-messages": errors,
                                                                      success: valid
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "append",
                                                                          fn: function() {
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      "",
                                                                                    icon:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      on.click(
                                                                                        $event
                                                                                      )
                                                                                      _vm.openDatePicker = true
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      attrs: {
                                                                                        color:
                                                                                          "#3F51B5"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm.mdiCalendar
                                                                                          ) +
                                                                                          " "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          },
                                                                          proxy: true
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                    model: {
                                                                      value:
                                                                        _vm.selectedMatchValue,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.selectedMatchValue = $$v
                                                                      },
                                                                      expression:
                                                                        "selectedMatchValue"
                                                                    }
                                                                  },
                                                                  on
                                                                )
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.openDatePicker,
                                          callback: function($$v) {
                                            _vm.openDatePicker = $$v
                                          },
                                          expression: "openDatePicker"
                                        }
                                      },
                                      [
                                        _vm.openDatePicker
                                          ? _c("v-date-picker", {
                                              directives: [
                                                {
                                                  name: "click-outside",
                                                  rawName: "v-click-outside",
                                                  value:
                                                    _vm.onDatePickerClickOutside,
                                                  expression:
                                                    "onDatePickerClickOutside"
                                                }
                                              ],
                                              attrs: {
                                                range:
                                                  _vm.selectedMatchChoice ===
                                                  _vm.MATCH_CHOICE_VALUES.RANGE
                                              },
                                              on: {
                                                "click:date": function($event) {
                                                  return _vm.onDateClick()
                                                }
                                              },
                                              model: {
                                                value: _vm.selectedMatchValue,
                                                callback: function($$v) {
                                                  _vm.selectedMatchValue = $$v
                                                },
                                                expression: "selectedMatchValue"
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      color: "#3F51B5",
                                      disabled: !isFormValid
                                    },
                                    on: { click: _vm.addToFilter }
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        " " + _vm._s(_vm.mdiFilterPlus) + " "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end px-5 py-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    elevation: "2",
                    color: "#3F51B5",
                    disabled: !_vm.filterChanged,
                    dark: ""
                  },
                  on: { click: _vm.updateFilter }
                },
                [_vm._v(" Update Filter ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-dialog :value="showFilesFilterDialog" max-width="550px" persistent>
    <v-card>
      <v-toolbar dark color="#3F51B5" class="elevation-0">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="$emit(`files-filter-dialog-close`)">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-3">
        <div>Filter</div>
        <v-card class="mb-5 px-0" elevation="0">
          <v-card-text class="px-0 mx-0">
            <div class="d-flex flex-wrap gap">
              <v-chip
                v-for="f of filterChoices"
                :key="f.selectedField"
                close
                @click:close="removeFilter(f.selectedField)"
              >
                {{ f.selectedField | filesFieldMap }}
                <MatchIcon :value="f.selectedMatchChoice" class="mx-2" />
                {{ f.selectedMatchValue }}
              </v-chip>
            </div>
          </v-card-text>
        </v-card>

        <div>Add to Filter</div>
        <validation-observer
          ref="filterForm"
          slim
          v-slot="{ valid: isFormValid }"
        >
          <v-card>
            <v-card-text
              class="align-center justify-space-between gap"
              :class="{ 'd-flex': $vuetify.breakpoint.smAndUp }"
            >
              <v-select
                :items="fieldChoices"
                v-model="selectedField"
                item-text="label"
                item-value="value"
                label="Field"
                :style="{
                  width: $vuetify.breakpoint.smAndUp ? '100%' : undefined,
                }"
                hide-details
              >
              </v-select>

              <v-select
                :items="filteredMatchChoices"
                v-model="selectedMatchChoice"
                item-text="label"
                item-value="value"
                flat
                hide-details
                :class="{ 'field-select': $vuetify.breakpoint.smAndUp }"
                style="width: 100px"
              >
                <template #item="{ item: { label, value } }">
                  <div>
                    <MatchIcon :value="value" />
                    {{ label }}
                  </div>
                </template>

                <template #selection="{ item: { label, value } }">
                  <div>
                    <MatchIcon :value="value" />
                    {{ $vuetify.breakpoint.smAndUp ? undefined : label }}
                  </div>
                </template>
              </v-select>

              <div
                :style="{
                  width: $vuetify.breakpoint.smAndUp ? '100%' : undefined,
                }"
              >
                <v-menu
                  v-model="openDatePicker"
                  :close-on-click="false"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Value"
                      rules="required"
                    >
                      <v-text-field
                        v-on="on"
                        v-model="selectedMatchValue"
                        hide-details
                        :error-messages="errors"
                        :success="valid"
                      >
                        <template #append>
                          <v-btn
                            small
                            icon
                            @click="
                              on.click($event);
                              openDatePicker = true;
                            "
                          >
                            <v-icon color="#3F51B5">
                              {{ mdiCalendar }}
                            </v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker
                    v-if="openDatePicker"
                    v-model="selectedMatchValue"
                    :range="selectedMatchChoice === MATCH_CHOICE_VALUES.RANGE"
                    v-click-outside="onDatePickerClickOutside"
                    @click:date="onDateClick()"
                  >
                  </v-date-picker>
                </v-menu>
              </div>

              <v-btn
                icon
                color="#3F51B5"
                @click="addToFilter"
                :disabled="!isFormValid"
              >
                <v-icon>
                  {{ mdiFilterPlus }}
                </v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </validation-observer>
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn
          elevation="2"
          color="#3F51B5"
          @click="updateFilter"
          :disabled="!filterChanged"
          dark
        >
          Update Filter
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiCalendar, mdiFilterPlus } from "@mdi/js";
import FIELD_TYPES from "@/constants/fieldTypes";
import MATCH_CHOICE_VALUES from "@/constants/matchChoiceValues";
import MatchIcon from "@/components/list/gis-data-point-filter-dialog/MatchIcon";

const fieldChoices = [
  { label: "Date", value: "date", fieldType: FIELD_TYPES.DATE },
];

export default {
  name: "FilesFilterDialog",
  props: {
    showFilesFilterDialog: Boolean,
    savedFilterChoices: Array,
  },
  components: {
    MatchIcon,
  },
  computed: {
    fieldType() {
      return this.fieldChoices.find((f) => f.value === this.selectedField)
        ?.fieldType;
    },
    filteredMatchChoices() {
      const { fieldType, selectedField } = this;
      if (fieldType === FIELD_TYPES.DATE) {
        if (["date"].includes(selectedField)) {
          return [
            {
              label: "On or After",
              value: MATCH_CHOICE_VALUES.ON_OR_AFTER,
            },
            {
              label: "On or Before",
              value: MATCH_CHOICE_VALUES.ON_OR_BEFORE,
            },
            {
              label: "Range",
              value: MATCH_CHOICE_VALUES.RANGE,
            },
          ];
        }
      }
      return [];
    },
  },
  data() {
    return {
      mdiClose,
      mdiCalendar,
      mdiFilterPlus,
      fieldChoices,
      selectedField: FIELD_TYPES.DATE,
      selectedMatchChoice: MATCH_CHOICE_VALUES.ON_OR_AFTER,
      selectedMatchValue: undefined,
      openDatePicker: false,
      FIELD_TYPES,
      MATCH_CHOICE_VALUES,
      filterChoices: [],
      filterChanged: false,
    };
  },
  methods: {
    removeFilter(selectedField) {
      const index = this.filterChoices.find(
        (f) => f.selectedField === selectedField
      );
      this.filterChoices.splice(index, 1);
    },
    addToFilter() {
      const { selectedField, selectedMatchChoice, selectedMatchValue } = this;
      const filter = { selectedField, selectedMatchChoice, selectedMatchValue };
      this.removeFilter(selectedField);
      this.filterChoices.push(filter);
      this.filterChanged = true;
      this.$refs.filterForm?.reset();
    },
    updateFilter() {
      this.$emit("update-filter", this.filterChoices);
    },
    onDateClick() {
      if (this.selectedMatchChoice === MATCH_CHOICE_VALUES.RANGE) {
        this.openDatePicker = (this.selectedMatchValue?.length ?? 0) < 2;
      } else {
        this.openDatePicker = false;
      }
    },
    onDatePickerClickOutside() {
      this.openDatePicker = false;
    },
  },
  watch: {
    selectedField() {
      this.selectedMatchValue = undefined;
    },
    selectedMatchChoice() {
      this.selectedMatchValue = undefined;
    },
  },
};
</script>
